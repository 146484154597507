import React from 'react'
import JSIcon from "../images/JavaScript-Blue.svg"
import ReactIcon from "../images/React-Blue.svg"
import CSharp from "../images/CSharp-Blue.svg"
import Python from "../images/Python-Blue.svg"
import NodeJS from "../images/NodeJS-Blue.svg"

import NetCore from "../images/NetCore-Blue.svg"
import GraphQL from "../images/GraphQL-Blue.svg"
import API from "../images/API-Blue.svg"

import { Link } from "gatsby"
import arrow from '../images/arrow.png'

const WebApplicationDevelopment = ({ data }) => {
    return (
        <div>
            <div className='p-and-button'>
                <p className='main-peragraph'>
                    We excel in tailoring digital solutions to match your goals—be it an e-commerce platform, data-driven analytics, or a customer-friendly portal. Our expert team collaborates to exceed your expectations by prioritizing user experience, security, and scalability from concept to deployment.
                </p>
                <Link to='/services/webdevelopment' >
                    <button className='readMoreBtn'>
                        Read more
                        <img src={arrow} className='errowImage' alt='read more arrow'/>
                    </button>
                </Link>
            </div>

            <div className='icons-and-caseStudy'>
                <div className="icons">
                    <div >
                        <img src={JSIcon} alt='JS icon'/>
                        <img src={NetCore} alt='NetCore icon'/>
                        <img src={CSharp} alt='CSharp icon'/>
                        <img src={ReactIcon} alt='React icon'/>
                        <img src={NodeJS} alt='NodeJS icon'/>
                        <img src={Python} alt='PY icon'/>
                        <img src={GraphQL} alt='Graphql icon'/>
                        <img src={API} alt='API icon'/>
                    </div>
                </div>
                <div className='caseStudy'>
                    <h1 className='h1caseStudyH1'>Recent Work</h1>
                    <div className='flex-div'>
                        <div>
                            <div className='webcaseDiv1'
                                style={{ backgroundImage: `url(${data.webcaseDiv1.childImageSharp.fluid.src})` }}
                            ></div>
                            <h1>CMS (Class Management System)</h1>
                            <p>Institute Structure, Class Structure, Class building, Admissions, Schedule (Auto Generated or Manually Manageable), Dynamic Forms Generator, Flexible Fee Structure and Invoices.</p>
                        </div>
                        <div>
                            <div className='webcaseDiv2'
                                style={{ backgroundImage: `url(${data.webcaseDiv2.childImageSharp.fluid.src})` }}
                            ></div>
                            <h1>DiversePro (Web-based APIs project)</h1>
                            <p>Allows users to find lawyers with cultural, linguistic, and experiential knowledge. Communities, Post, Stories (Status), Live Chat, In-App purchase, Groups, and Post Reactions.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default WebApplicationDevelopment
