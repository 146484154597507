import React from 'react'
import Odoo from "../images/odoo_c 2.png"
import MicrosoftDynamics from "../images/msdynamics_c 2.png"
import { Link } from "gatsby"
import arrow from '../images/arrow.png'

const ErpOdooDynamics = ({ data }) => {
    return (
        <div>
            <div className='p-and-button'>
                <p className='main-peragraph'>
                    With Dynamics 365, access a robust suite of integrated business apps, covering CRM to ERP and beyond. Odoo ERP's modular design allows tailored solutions aligning perfectly with your needs. Our adept team excels in seamless integration of Dynamics 365 & Odoo with third-party solutions, and offers our own products integrated with both.
                </p>
                <Link to='/services/erpdevelopment' >
                    <button className='readMoreBtn'>
                        Read more
                        <img src={arrow} className='errowImage' alt='read more arrow'/>
                    </button>
                </Link>
            </div>

            <div className='icons-and-caseStudy'>
                <div className="icons">
                    <div className='singleGrid'>
                        <img src={MicrosoftDynamics} className='MicrosoftDynamics' alt='MicrosoftDynamics'/>
                        <img src={Odoo} className='Odoo' alt='Odoo'/>
                    </div>
                </div>
                <div className='caseStudy'>
                    <h1 className='h1caseStudyH1'>Recent Work</h1>
                    <div className='flex-div'>
                        <div>
                            <div className='erpcaseDiv1'
                                style={{ backgroundImage: `url(${data.erpcaseDiv1.childImageSharp.fluid.src})` }}></div>
                            <h1>Distribution Utility for Transfers & Whole Sales Order</h1>
                            <p>Application significantly reduces the burden on warehouse operations by swiftly creating multiple transfer orders outside the Microsoft Dynamics 365 environment.</p>
                        </div>
                        <div>
                            <div className='erpcaseDiv2'
                                style={{ backgroundImage: `url(${data.erpcaseDiv2.childImageSharp.fluid.src})` }}></div>
                            <h1>Xstak Connector for Microsoft Dynamics 365</h1>
                            <p>Introducing Xstack connector, XStak OE clients can effortlessly synchronize inventory, orders, invoices, and even POS integration with FBR platform. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErpOdooDynamics
