import React, { useState, memo } from "react"
import behindImageLarg from "../images/Maskgroup.png"
import behindImageSmall from "../images/Maskgroup1.png"

const HowWeWork = ({ data }) => {
  const [isElementInView, setisElementInView] = useState(false)
  if (!isElementInView && typeof document !== "undefined") {
    document.addEventListener("scroll", () => {
      if (
        document.querySelector(".process-within-home-page") &&
        document
          .querySelector(".process-within-home-page")
          .getBoundingClientRect().top <= window.innerHeight
      ) {
        setisElementInView(true)
      }
    })
  }

  return (
    <div className="process-within-home-page">
      <div className="heading-container">
        <span className="heading">Why Work with us?</span>
      </div>
      <div className="process-steps-wrapper">
        <div className="our-working">
          <div className="title">Punctual & Cost-Effective</div>
          <div className="description">
            We employ a well-established agile methodology to ensure the swift
            and efficient delivery of your software project
          </div>
        </div>
        <div className="our-working">
          <div className="title">Exploration Stage</div>
          <div className="description">
            We provide a 2 to 5 weeks service designed to bring your vision to
            life and establish a clear and comprehensive project scope
          </div>
        </div>
        <div className="our-working">
          <div className="title">MVP Development</div>
          <div className="description">
            We specialize in crafting MVPs that not only expedite time-to-market
            but also lay the foundation for robust, full-scale software
            products.
          </div>
        </div>
        <div className="our-working">
          <div className="title">Tailored to Your Business Needs</div>
          <div className="description">
            We have earned the trust of startups, small businesses, and large
            enterprises alike.
          </div>
        </div>
        <div className="our-working">
          <div className="title">Dedicated Support</div>
          <div className="description">
            A 30-day warranty and round-the-clock support, backed by clearly
            defined SLAs to ensure the uninterrupted operation of your software
          </div>
        </div>
        <div className="our-working">
          <div className="title">IP Protection</div>
          <div className="description">
            The intellectual property rights for the software we create for you
            will be unequivocally yours.
          </div>
        </div>
      </div>
      <img className="behindImageLarg" src={behindImageLarg} alt="visiomate" />
      <img
        className="behindImageSmall"
        src={behindImageSmall}
        alt="visiomate"
      />
    </div>
  )
}

export default memo(HowWeWork)
