import React, { useState, memo } from "react"
import { Typography } from "antd"

import JSIcon from "../images/javascript.svg"
import TSIcon from "../images/typescript.svg"
import Html5 from "../images/html5.svg"
import Css3 from "../images/css3.svg"
import ReactIcon from "../images/react.svg"
import Sass from "../images/sass.svg"
import Bootstrap from "../images/bootstrap.svg"
import CSharp from "../images/csharp.svg"
import Python from "../images/python.svg"
import Django from "../images/django.svg"
import NodeJS from "../images/nodejs.svg"
import MySql from "../images/mysql.svg"

import NetCore from "../images/netcore.svg"
import ExpressJS from "../images/ExpressJS.svg"
import GraphQL from "../images/GraphQL.svg"
import API from "../images/api.svg"
import Android from "../images/android.svg"
import IOS from "../images/ios.svg"
import Dart from "../images/dart.svg"
import Flutter from "../images/flutter.svg"
import ReactNative from "../images/react-native.svg"
import MongoDB from "../images/mongodb.svg"
import PostreSQL from "../images/posgresql.svg"
import MSSQL from "../images/mssql.svg"
import Oracle from "../images/oracle.svg"
import AWS from "../images/aws.svg"
import AWSLambda from "../images/aws-lambda.svg"
import Azure from "../images/azure.svg"
import AzureDataFactory from "../images/azuredatafactory.svg"
import Odoo from "../images/odoo.svg"
import MicrosoftDynamics from "../images/msdynamics.svg"
import powerBI from "../images/PowerBI.svg"
import Informatica from "../images/informatica.svg"

import { technologies } from "../data"
const { Title } = Typography

const getIconAndText = (tech, isFirstRow) => {
  switch (tech) {
    case "Frontend":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img src={Html5} alt="html5" />
            <Title level={5}>HTML 5</Title>
          </div>
          <div className="icon-with-text">
            <img src={Css3} alt="css3" />
            <Title level={5}>CSS 3</Title>
          </div>
          <div className="icon-with-text">
            <img src={Sass} alt="sass / scss" />
            <Title level={5}>Sass</Title>
          </div>
          <div className="icon-with-text">
            <img src={Bootstrap} alt="Bootstrap" />
            <Title level={5}>Bootstrap</Title>
          </div>
          <div className="icon-with-text">
            <img src={JSIcon} alt="javascript" />
            <Title level={5}>JavaScript</Title>
          </div>
        </>
      ) : (
        <>
          <div className="icon-with-text">
            <img src={TSIcon} alt="TypeScript" />
            <Title level={5}>TypeScript</Title>
          </div>
          <div className="icon-with-text">
            <img src={ReactIcon} alt="react js" />
            <Title level={5}>React JS</Title>
          </div>
        </>
      )
    case "Backend":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img src={CSharp} alt="CSharp" />
            <Title level={5}>C Sharp</Title>
          </div>
          <div className="icon-with-text">
            <img src={Python} alt="Python" />
            <Title level={5}>Python</Title>
          </div>
          <div className="icon-with-text">
            <img src={NetCore} alt="Asp .net" />
            <Title level={5}>Asp .net</Title>
          </div>
          <div className="icon-with-text" key={Math.random(Math.random())}>
            <img src={Django} alt="Django" />
            <Title level={5}>Django</Title>
          </div>
          <div className="icon-with-text">
            <img src={NodeJS} alt="NodeJS" />
            <Title level={5}>NodeJS</Title>
          </div>
        </>
      ) : (
        <>
          <div className="icon-with-text">
            <img
              src={ExpressJS}
              alt="Express JS"
              style={{ transform: "scale(1.8)" }}
            />
            <Title level={5}>Express JS</Title>
          </div>
          <div className="icon-with-text">
            <img src={GraphQL} alt="GraphQL" />
            <Title level={5}>GraphQL</Title>
          </div>
          <div className="icon-with-text" key={Math.random(Math.random())}>
            <img src={API} alt="REST API's" />
            <Title level={5}>REST API's</Title>
          </div>
        </>
      )
    case "Mobile":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img src={Android} alt="Andriod" />
            <Title level={5}>Andriod</Title>
          </div>
          <div className="icon-with-text">
            <img src={IOS} alt="IOS" />
            <Title level={5}>IOS</Title>
          </div>
          <div className="icon-with-text">
            <img src={ReactNative} alt="React Native" />
            <Title level={5}>React Native</Title>
          </div>
        </>
      ) : (
        <>
          <div className="icon-with-text">
            <img src={Dart} alt="Dart" />
            <Title level={5}>Dart</Title>
          </div>
          <div className="icon-with-text">
            <img src={Flutter} alt="Flutter" />
            <Title level={5}>Flutter</Title>
          </div>
        </>
      )
    case "Database":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img src={MSSQL} alt="MS SQL Server" />
            <Title level={5}>MS SQL Server</Title>
          </div>
          <div className="icon-with-text">
            <img src={MySql} alt="MySQL" />
            <Title level={5}>MySQL</Title>
          </div>
          <div className="icon-with-text">
            <img src={PostreSQL} alt="PosgreSQL" />
            <Title level={5}>PostgreSQL</Title>
          </div>
        </>
      ) : (
        <>
          <div className="icon-with-text">
            <img src={Oracle} alt="Oracle" />
            <Title level={5}>Oracle</Title>
          </div>
          <div className="icon-with-text">
            <img
              src={MongoDB}
              alt="MongoDB"
              style={{ transform: "scale(1.5)" }}
            />
            <Title level={5}>MongoDB</Title>
          </div>
        </>
      )
    case "ERP":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img
              src={MicrosoftDynamics}
              alt="Microsoft D365"
              style={{ transform: "scale(2.5)" }}
            />
            <Title level={5}>Microsoft D365</Title>
          </div>
          <div className="icon-with-text">
            <img src={Odoo} alt="Odoo" />
            <Title level={5}>Odoo</Title>
          </div>
        </>
      ) : (
        <></>
      )
    case "Infra & Devops":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img src={AWS} alt="AWS" />
            <Title level={5}>AWS</Title>
          </div>
          <div className="icon-with-text">
            <img src={Azure} alt="Microsoft Azure" />
            <Title level={5}>Microsoft Azure</Title>
          </div>
          <div className="icon-with-text">
            <img src={AWS} alt="Cloud RDS" />
            <Title level={5}>Cloud RDS</Title>
          </div>
          <div className="icon-with-text">
            <img src={AWSLambda} alt="AWS Lambda" />
            <Title level={5}>Lambda</Title>
          </div>
        </>
      ) : (
        <>
          <div className="icon-with-text">
            <img src={AzureDataFactory} alt="Azure Data Factory" />
            <Title level={5}>Azure Data Factory</Title>
          </div>
          <div className="icon-with-text">
            <img src={AWS} alt="AWS S3" />
            <Title level={5}>AWS S3</Title>
          </div>
        </>
      )
    case "BI & Reporting":
      return isFirstRow ? (
        <>
          <div className="icon-with-text">
            <img
              src={powerBI}
              alt="Power BI"
              style={{
                height: "40px",
                width: "60px",
                marginBottom: "0.7rem",
              }}
            />
            <Title level={5}>Power BI</Title>
          </div>
          <div className="icon-with-text">
            <img
              src={Informatica}
              alt="Informatica"
              style={{
                transform: "scale(3.5)",
              }}
            />
            <Title level={5}>Informatica</Title>
          </div>
        </>
      ) : (
        <></>
      )
    default:
      break
  }
}
const Technologies = () => {
  const [selectedTech, setSelectedTech] = useState("Frontend")

  return (
    <div className="technologies-within-home-page">
      <div className="news-Heading-container">
        <div className="heading-postion">
          <span  style={{ color: "white", fontSize:"36px" }} className="news-heading">
            Technolgies We Work With
          </span>
        </div>
      </div>
      <div className="technologies-tabs-wrapper">
        <div className="technologies-tabs">
          {technologies.map(technology => (
            <div
              key={Math.random(Math.random())}
              onClick={() => setSelectedTech(technology)}
              className={`tab ${technology === selectedTech ? "selected-tab" : ""}`}
              role="button"
            >
              {technology}
            </div>
          ))}
        </div>
        {technologies.map(
          t =>
            t === selectedTech && (
              <div key={Math.random(Math.random())} className="technologies">
                {getIconAndText(t, true)}
              </div>
            )
        )}
        {technologies.map(
          t =>
            t === selectedTech && (
              <div key={Math.random(Math.random())} className="technologies">
                {getIconAndText(t)}
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default memo(Technologies)
