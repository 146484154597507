import React from "react"
import Android from "../images/Android-Blue.svg"
import IOS from "../images/IOS-Blue.svg"
import Dart from "../images/Dart-Blue.svg"
import Flutter from "../images/Flutter-Blue.svg"
import { Link } from "gatsby"
import arrow from "../images/arrow.png"
const MobileApplicationDevelopment = ({ data }) => {
    return (
        <div>
            <div className="p-and-button">
                <p className="main-peragraph">
                    Our mobile app development services bring your unique vision to life,
                    meeting the rising demand for innovative and user-friendly mobile
                    experiences. Our skilled team turns your ideas into fully functional,
                    visually engaging, and intuitive apps for iOS, Android, or
                    cross-platform. Our process spans ideation, design, coding, rigorous
                    testing, and deployment.{" "}
                </p>
                <Link to="/services/mobiledevelopment">
                    <button className="readMoreBtn">
                        Read more
                        <img src={arrow} className="errowImage" alt="Read more arrow"/>
                    </button>
                </Link>
            </div>
            <div className="icons-and-caseStudy">
                <div className="icons">
                    <div>
                        <img src={Android} alt="Android"/>
                        <img src={Dart} alt="Dart"/>
                        <img src={IOS} className=" mobile-App-Icons-edjust" alt="IOS"/>
                        <img src={Flutter} className=" mobile-App-Icons-edjust" alt="Flutter"/>
                    </div>
                </div>
                <div className="caseStudy">
                    <h1 className="h1caseStudyH1">Recent Work</h1>
                    <div className="flex-div">
                        <div>
                            <div
                                className="mobilecaseDiv1"
                                style={{
                                    backgroundImage: `url(${data.mobilecaseDiv1.childImageSharp.fluid.src})`,
                                }}
                            ></div>
                            <h1>Umrah Shuttle</h1>
                            <p>
                                This application bridge the travel agents and Umrah visitors,
                                Umrah shuttle booking, Tracking custoner and driver's location,
                                etc.
                            </p>
                        </div>
                        <div>
                            <div
                                className="mobilecaseDiv2"
                                style={{
                                    backgroundImage: `url(${data.mobilecaseDiv2.childImageSharp.fluid.src})`,
                                }}
                            ></div>
                            <h1>Big Jerry's Fencing Application</h1>
                            <p>
                                Fencing installation staff Application, Installer can check
                                assigned jobs, process a job, upload evidance and checklists
                                regarding the job complition.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileApplicationDevelopment
