import React, { memo, useState } from "react"
import WebApplicationDevelopment from "./WebApplicationDevelopment"
import MobileApplicationDevelopment from "./MobileApplicationDevelopment"
import ErpOdooDynamics from "./ErpOdooDynamics"
import PowerPowerApps from "./PowerPowerApps"
import webColorIcon from '../images/web_color1-icon.png'
import webGrayIcon from '../images/web_gray1-icon.png'
import mobileColorIcon from '../images/mobile/mobile_color1-icon.png'
import mobileGrayIcon from '../images/mobile/mobile_gray1-icon.png'
import biColorIcon from '../images/BI_color1-icon.png'
import biGrayIcon from '../images/BI_gray1-gray.png'
import d365ColorIcon from '../images/D365/ERP_color1-icon.png'
import d365GrayIcon from '../images/D365/ERP_gray1-icon.png'
import StrongestCompetenciesmobile from "./StrongestCompetenciesmobile";


const Services = ({ data }) => {
  const [currentTab, setCurrentTab] = useState("Web-Application")
  const [prevTab, setPrevTab] = useState("Web-Application")
  return (
    <div className="OurStrongestCompetencies">
      <div className="header">
        <h1> Our Strongest Competencies</h1>
      </div>
      
  <div className="mobile-view">
      <StrongestCompetenciesmobile title={"Web Development"} description={<WebApplicationDevelopment data={data} />} 
      // image={data.webColorIcon.childImageSharp.fluid}
      image={webColorIcon}
      />
      <StrongestCompetenciesmobile title={"Mobile Development"} description={<MobileApplicationDevelopment data={data} />} 
      // image={data.mobileColorIcon.childImageSharp.fluid}
      image={mobileColorIcon}
      />
      <StrongestCompetenciesmobile title={"Dynamics 365 & Odoo ERP"} description={<ErpOdooDynamics data={data} />} 
      // image={data.biColorIcon.childImageSharp.fluid}
      image={biColorIcon}
      />
      <StrongestCompetenciesmobile title={"Power BI & Power Apps"} description={<PowerPowerApps data={data} />} 
      // image={data.d365ColorIcon.childImageSharp.fluid}
      image={d365ColorIcon}
      />
      </div>
<div className="browser-view">
     <div className="Our-grid">
        <div className="our-first">
          <div className="selection-section">
            <div
              onClick={() => {
                setCurrentTab("Web-Application")
              }}
              className={`sidebar-a ${currentTab === "Web-Application" ? "active" : ""
                ||(prevTab==="Web-Application"&&"hover-div")}`}
                onMouseEnter={() => {
                  setPrevTab("Web-Application")
                }}
                onMouseLeave={()=>{
                  setPrevTab("")
                }}
            >
              <img src={currentTab === "Web-Application"||prevTab==="Web-Application"?webColorIcon:webGrayIcon} alt="Web icon"/>
              <h6>Web Development</h6>
            </div>
            <div
              onClick={() => {
                setCurrentTab("Mobile-Application")
              }}
              onMouseEnter={() => {
                setPrevTab("Mobile-Application")
              }}
              onMouseLeave={()=>{
                setPrevTab("")
              }}
              className={`sidebar-a ${currentTab === "Mobile-Application" ? "active" : ""
                } ${prevTab==="Mobile-Application"&&"hover-div"}`}
            >
              <img src={currentTab === "Mobile-Application"||prevTab==="Mobile-Application"?mobileColorIcon:mobileGrayIcon} alt="Mobile icon"/>
              <h6>Mobile Development</h6>
            </div>
            <div
              onClick={() => {
                setCurrentTab("Dynamics-365")
              }}
              className={`sidebar-a ${currentTab === "Dynamics-365" ? "active" : "" ||(prevTab==="Dynamics-365"&&"hover-div")}`}
                onMouseEnter={() => {
                  setPrevTab("Dynamics-365")
                }}
                onMouseLeave={()=>{
                  setPrevTab("")
                }}
                // style={{padding:"1rem"}}
            > 
            <img src={currentTab === "Dynamics-365"||prevTab==="Dynamics-365"?d365ColorIcon:d365GrayIcon} alt="d365 icon"/>
              <h6>Dynamics 365 & Odoo ERP</h6>
            </div>
            <div
              onClick={() => {
                setCurrentTab("Power-BI")
              }}
              className={`sidebar-a ${currentTab === "Power-BI" ? "active" : ""
                ||(prevTab==="Power-BI"&&"hover-div")}`}
                onMouseEnter={() => {
                  setPrevTab("Power-BI")
                }}
                onMouseLeave={()=>{
                  setPrevTab("")
                }}
            >
               <img src={currentTab === "Power-BI"||prevTab==="Power-BI"?biColorIcon:biGrayIcon} alt="Power-Bi icon"/>
              <h6>Power BI & Power Apps</h6>
            </div>
          </div>
        </div>
        <div className="our-second">
          {currentTab === "Web-Application" && (
            <WebApplicationDevelopment data={data} />
          )}
          {currentTab === "Mobile-Application" && (
            <MobileApplicationDevelopment data={data} />
          )}
          {currentTab === "Dynamics-365" && <ErpOdooDynamics data={data} />}
          {currentTab === "Power-BI" && <PowerPowerApps data={data} />}
        </div>
      </div>
      </div>
    </div>
  )
}

export default memo(Services)
