import React from 'react'

function StrongestCompetenciesmobile({image,title,description}) {
  return (
    <div className="mobile-layout">
    <div className="our-first" style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
              {/* <Img fluid={image}/> */}
              <img src={image} alt='Not Found'/>
              <h6 className="header-text">{title}</h6>
            </div>
            <div className="our-second">
            {description}
            </div>
            </div>
  )
}

export default StrongestCompetenciesmobile