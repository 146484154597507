import React from "react"
import powerBI from "../images/PowerBI_c 2.png"
import PowerApps from "../images/powerapp_c 2.png"
import { Link } from "gatsby"
import arrow from "../images/arrow.png"

const PowerPowerApps = ({ data }) => {
    return (
        <div>
            <div className="p-and-button">
                <p className="main-peragraph">
                    Our Power BI and Power Apps services drive data innovation in your
                    organization. Power BI extracts insights from your data, creating
                    actionable reports and interactive dashboards. Understand trends, make
                    informed decisions, and optimize processes. Power Apps enables agile,
                    code-free app creation.{" "}
                </p>
                <Link to="/contact">
                    <button className="readMoreBtn">
                        Read more
                        <img src={arrow} className="errowImage" alt="Read more arrow"/>
                    </button>
                </Link>
            </div>

            <div className="icons-and-caseStudy">
                <div className="icons">
                    <div className="singleGrid">
                        <img src={PowerApps} className="PowerApps" alt="PowerApps"/>
                        <img src={powerBI} className="powerBI"  alt="PowerBI"/>
                    </div>
                </div>
                <div className="caseStudy">
                    <h1 className="h1caseStudyH1">Recent Work</h1>
                    <div className="flex-div">
                        <div>
                            <div
                                className="bicaseDiv1"
                                style={{
                                    backgroundImage: `url(${data.bicaseDiv1.childImageSharp.fluid.src})`,
                                }}
                            ></div>
                            <h1>Power BI Dashboards</h1>
                            <p>
                                MERCK wanted to have some dashboards for there portfolio data.
                                The data was in Excel sheets and we had to prepare data using
                                data modeling feature of power BI.{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PowerPowerApps
