import React, { memo } from "react"
import { Card, Button } from "antd"
import Img from "gatsby-image"
import { RightOutlined } from "@ant-design/icons"

const getProjectImage = (title, data) => {
  switch (title) {
    case "Education Management System":
      return <Img fluid={data.EMS.childImageSharp.fluid} alt={title} />
    case "SNGPL Mobile Application":
      return <Img fluid={data.SNGPL.childImageSharp.fluid} alt={title} />
    case "State Bank of Pakistan Mobile Application":
      return <Img fluid={data.SBP.childImageSharp.fluid} alt={title} />
    case "Big Jerry's Fencing App":
      return <Img fluid={data.Bigjerry.childImageSharp.fluid} alt={title} />
    case "Umrah Shuttle App":
      return <Img fluid={data.UmrahShuttle.childImageSharp.fluid} alt={title} />
    case "Farm/Poultry Management System":
      return (
        <Img
          fluid={data.FarmManagementSystem.childImageSharp.fluid}
          alt={title}
        />
      )
    case "Distribution with D365":
      return (
        <Img fluid={data.DistributionD365.childImageSharp.fluid} alt={title} />
      )
    case "Visioedge A Complete Solution":
      return <Img fluid={data.Visioedge.childImageSharp.fluid} alt={title} />
    case "Tender Asset Record Management":
      return (
        <Img
          fluid={data.TenderAssetRecordManagement.childImageSharp.fluid}
          alt={title}
        />
      )
    case "VIZTOS POS":
      return <Img fluid={data.ViztosPos.childImageSharp.fluid} alt={title} />
    case "ORR Protection USA Custom Solution Development":
      return <Img fluid={data.Custom.childImageSharp.fluid} alt={title} />
    case "Banu Mukhtar Odoo CRM Solution":
      return <Img fluid={data.Odoo2.childImageSharp.fluid} alt={title} />
    case "ORR Protection Odoo Support":
      return <Img fluid={data.Odoo2.childImageSharp.fluid} alt={title} />
    case "Cake & Bakes ERP Implementation and Support":
      return <Img fluid={data.D365_2.childImageSharp.fluid} alt={title} />
    case "Firhaj Footwear (Pvt.) Ltd ERP Support":
      return <Img fluid={data.D365_2.childImageSharp.fluid} alt={title} />
    case "MIA Group Companies ERP Implementation and Support":
      return <Img fluid={data.D365_2.childImageSharp.fluid} alt={title} />
    case "ZiePie Azure Services Support":
      return <Img fluid={data.Azure.childImageSharp.fluid} alt={title} />
    case "ZiePie PowerBI development":
      return <Img fluid={data.PowerBI.childImageSharp.fluid} alt={title} />
    default:
      return <Img fluid={data.Odoo2.childImageSharp.fluid} alt={title} />
  }
}
const Portfolio = ({ className, items, data }) => {
  const { Meta } = Card

  return (
    <div className={`porfolio-within-home-page  ${className || ""}`}>
      {!className && (
        <div className="news-Heading-container">
          <div className="heading-postion">
            <span className="news-heading" style={{ color: "#000000" }}>
              Our Portfolio
            </span>
          </div>
        </div>
      )}

      <div className="porfolio-cards-wrapper">
        {items &&
          items.map((i, index) => (
            <Card
              key={Math.random(Math.random())}
              cover={getProjectImage(i.title, data)}
              actions={[
                <Button type="link" href={i.link}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Read More
                    <RightOutlined style={{ marginLeft: "5px" }} />
                  </div>
                </Button>,
              ]}
            >
              <Meta
                title={
                  <div className="title-container">
                    <span className="name">{i.title}</span>
                    <span className="category">{i.category}</span>
                  </div>
                }
                description={i.description}
              />
              <div className="platform-type">
                <div>Platform:</div>
                <div>{i.platform}</div>
              </div>
              <div className="service-features">
                <div>
                  {i.type === "project" ? "Provided Services" : "Features"}:
                </div>
                <div>{i.FechorService}</div>
              </div>
            </Card>
          ))}
      </div>
    </div>
  )
}

export default memo(Portfolio)
