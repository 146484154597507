import React, { memo } from "react"
import Img from "gatsby-image"
import Averti from "../images/averti 1.png"
import BanuMukhtar from "../images/banomukhtar 1.png"
import OmanDataPark from "../images/image 7.png"
import ImesOman from "../images/image 8.png"
import Mtech from "../images/logo 1.png"
import OrrProtection from "../images/orr 1.png"
import SoharPort from "../images/soharport 1.png"
import SuiNorth from "../images/image 4.png"
import ZiePie from "../images/ziepie 1.png"
import HP from "../images/hp 1.png"
import KM from "../images/image 5.png"
import Borjan from "../images/image 3.png"
import CNb from "../images/cnb 1.png"
import NetAtlantic from "../images/about/NetAtlantic_logo.png"

const HappyClients = ({ data }) => {

  return (
    <div className="clients-within-home-page">
      <div className="happyClientContainer">
        <div className="backGroundimg">
          <Img fluid={data.BackImg.childImageSharp.fluid} />
        </div>
        <div className="first">
          <div className="news-Heading-container">
            <h1 className="news-heading customer-heading">Our Happy Customer</h1>
            <p>
              We have inspired trust in many organizations as their technology
              partner, helping them build, optimize, or enhance their IT
              solutions.
            </p>
          </div>
        </div>
        <div className="second">
          <div className="images-Div-1">
            <img src={OrrProtection} alt="OrrProtection Cmpy"/>
            <img src={NetAtlantic}  alt="NetAtlantic Cmpy"/>
            <img src={HP} alt="HushPuppies Cmpy"/>
            <img src={Mtech} alt="Mtech Cmpy"/>
            <img src={SoharPort} alt="SoharPort Cmpy"/>
          </div>
          <div className="images-Div-2">
            <img src={Averti} alt="Averti Cmpy"/>
            <img src={ImesOman} alt="ImesOman Cmpy"/>
            <img src={OmanDataPark} alt="OmanDataPark Cmpy"/>
            <img src={ZiePie} alt="ZiePie Cmpy"/>
          </div>
          <div className="images-Div-3">
            <img src={KM} alt="Kiondo_Market Cmpy"/>
            <img src={SuiNorth} alt="SuiNorth Cmpy"/>
            <img src={Borjan} alt="Borjan Cmpy"/>
            <img src={CNb} alt="Cakes&Bakes Cmpy"/>
            <img src={BanuMukhtar} alt="BanuMukhtar Cmpy"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(HappyClients)
